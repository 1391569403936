// Toggle global options
$enable-shadows: true;
$enable-negative-margins: true;

// Include functions first
@import "bootstrap/scss/functions";

// custom fonts
@import "font";

// Customize some defaults
$prefix: "td-";
$body-color: #090c08;
$body-bg: #eef0f2;
$border-radius: 0.4rem;
$primary: #ffbd13;
$link-color: shade-color($primary, 60%);
$link-color-hover: shade-color($primary, 70%);
$secondary: #27187e;
$info: #6a8d92;
$font-size-base: 1.25rem;
$font-family-sans-serif:
  LilGrotesk,
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";

// Required
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/close";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/card";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/offcanvas";

// Helpers
@import "bootstrap/scss/helpers";

// Custom components
@import "icon";
@import "text";
@import "cursor";
@import "card";
@import "offset";
@import "blockquote";
@import "offcanvas-popover";
@import "sizing";

// Utilities
@import "bootstrap/scss/utilities/api";

.type-cursor {
  color: $primary;
  animation: cursor-blink 1.5s steps(2) infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.offcanvas {
  &:popover-open {
    visibility: visible;
    transform: none;
    height: auto;
    padding: 0;
    border: none;
    inset: unset;
    bottom: 0;

    &.offcanvas-end {
      top: 0;
      right: 0;
    }

    &.offcanvas-start {
      top: 0;
      left: 0;
    }
  }
}

/* Transition for the popover's backdrop */
[popover] {
  &::backdrop {
    background-color: rgb(0 0 0 / 0%);
    transition: all 0.3s allow-discrete;
  }
  
  &:popover-open::backdrop {
    background-color: rgb(0 0 0 / 25%);
  }
}

@starting-style {
  [popover]:popover-open::backdrop {
    background-color: rgb(0 0 0 / 0%);
  }
}
